import React from "react";



import './Benefits.css';
import BenefitsList from './components/BenefitsList/BenefitsList';

const Benefits = props => {


    return (
        <section className="Benefits__main-section-container">
        <BenefitsList></BenefitsList>
        </section>
    );
};

export default Benefits;