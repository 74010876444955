import React, { useEffect } from "react";

import WelcomeSection from '../../shared/components/WelcomeSection/WelcomeSection';
import unsubscribeBannerImg from '../../utils/assets/cancelService/want-to-unsuscribe.png';
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";
import Cancelation from '../components/Cancelation/Cancelation';

 

const CancelService = props => {

    useEffect(()=>{
        window.scroll(0,0);
    },[])
     const legendInfo =
     {
         cardTitle: "Do you want to cancel?",
         cardBodyText: `It is quick and easy to cancel your service. We hope that by choosing the reason for your cancellation, you will help us to improve our service.`,
         cardButtonText: "",
         lengendBackground: unsubscribeBannerImg
     }

    return (
        <React.Fragment>
            <WelcomeSection props={legendInfo}/>
            <NavegationInfo page="Cancel service"/>
            <Cancelation/>
            
            
        </React.Fragment>
    );
};

export default CancelService;