import React, {useState, useEffect }from "react";
import './SubscriptionDescription.css';

const SubscriptionDescription = ({ props }) => {

     const [currentPath, setCurrentPath] = useState('');

     useEffect(() => {
         setCurrentPath(window.location.pathname);
     }, []);

     let contentToRender ;
     switch( window.location.pathname ){
         case '/price':
             contentToRender = (
                 <p>
                      Find the protection and security you need to keep your 
                      identity and personal information safe from theft. <b>Save
                      money, time, and peace of mind knowing we have your back.
                      </b>
                 </p>
             )
             break;
         case '/':
             contentToRender = (
                 <p>
                      Save money thanks to our <b>incredible discounts on pet
                      medications, and get checkups when you need them
                      &nbsp;</b>so you
                      never have to spend money on non-emergency visits to the
                      vet
                 </p>
             );
             break;
 
         default:
             contentToRender = null;
             break;
     }
    return (
        <div className="SubscriptionDescription__main-container" >
            {currentPath === "/price" &&

                <div className="SubscriptionDescription__img-container">
                    <div className="SubscriptionDescription__inner-img-container">
                        <img src={props.img} alt={props.imgDescription} className="SubscriptionDescription__img"/>
                    </div>
                </div>
            }
            <div className="SubscriptionDescription__title-container">
                <p>
                    {props.title}
                </p>
            </div>
            <div className="SubscriptionDescription__description-container">
               
                {contentToRender}
                
            </div>
        </div>
    );
};

export default SubscriptionDescription;