import React from "react";

import './BenefitsList.css';


import vet247Icon from '../../../../../utils/assets/icons/vet-24-7-icon.png';
import emergencyIcon from '../../../../../utils/assets/icons/emergency-vet-icon.png';
import learnIcon from '../../../../../utils/assets/icons/learn-icon.png';
import drugsIcon from '../../../../../utils/assets/icons/drugs-icon.png';
import doubtsIcon from '../../../../../utils/assets/icons/doubts-icon.png';
import guidanceIcon from '../../../../../utils/assets/icons/guidance-icon.png';
const BenefitsList = props => {


    return (
        <div className="BenefitsList__main-container">
            <div className="BenefitsList__list-container">
                <div className="BenefitsList__first-benefits">


                    
                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={vet247Icon} alt="vet247Icon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Vet Care 24/7</h6>
                            <p className="BenefitsList__description">
                            Our pet telemedicine service connects
                            you to a veterinarian by video or phone
                            around the clock.
                            </p>
                        </div>
                    </div>

                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={doubtsIcon} alt="doubtsIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Guidance and Support</h6>
                            <p className="BenefitsList__description">
                            Provide follow-up care for treatments 
                            and routine checkups to monitor the
                            health of your pet.
                            </p>
                        </div>
                    </div>


                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={guidanceIcon} alt="guidanceIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Clear up any doubts</h6>
                            <p className="BenefitsList__description">

                            Ask questions, get a second opinion, and
                            understand if you need to take your pet
                            to the emergency room.
                            </p>
                        </div>
                    </div>
                    




                </div>




                <div className="BenefitsList__second-benefits">
                    

                <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={emergencyIcon} alt="emergencyIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Emergency recognition</h6>
                            <p className="BenefitsList__description">
                            We can help you determine if your pet is
                            in need of an emergency room visit or if 
                            they can be treated at home.
                            </p>
                        </div>     
                    </div>





                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={drugsIcon} alt="drugsIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Medicines for pets</h6>
                            <p className="BenefitsList__description">
                            Save up to 75% on medications for your
                            pet at more than 68,000 pharmacy 
                            locations in the United States
                            </p>
                        </div>
                    </div>



                    <div className="BenefitsList__item-container">
                        <div className="BenefitsList__icon-container">
                            <img src={learnIcon} alt="learnIcon" className="BenefitsList__icon" />
                        </div>
                        <div className="BenefitsList__description-container">
                            <h6 className="BenefitsList__title">Improve your pet's well-being</h6>
                            <p className="BenefitsList__description">
                            Learn how to train, solve behavior 
                            problems, and more from the comfort of
                            your own home.
                            </p>
                        </div>
                    </div>



                    
                </div>
            </div>
        </div>
    );
};

export default BenefitsList;