import React from "react";
import './ProductDescription.css';

const ProductDescription = ({ props }) => {

    let paragraphContent;

    switch (window.location.pathname) {
        case '/':
            paragraphContent = (
                <>
                    <p>
                        <b>
                        Pet Lovers is a monthly pet care service offering
                        veterinary telemedicine services to answer questions and
                        ease concerns.
                        </b>
                    </p>
                    <br />
                    <p>
                    These plans provide virtual medical care 24 hours a day, 7
                    days a week, 365 days a year,&nbsp;<b>saving you money on veterinary visits
                    and giving you great discounts on everything related to your
                    pet.
                    </b>
                    </p>
                    <br />
                    <p>
                    Get the fastest access to veterinary care for your pet in&nbsp;
                    <b>just 4 simple steps:</b>
                    </p>
                    <br />
                    <ol>
                        <li>All you need to do is download the app or go directly to  
                        your health portal on the web and enter your <b>member ID
                        and password.
                        </b>
                        </li>
                        <li>
                        Fill out your member profile and add any pets you have.
                        </li>
                        <li>
                        Select the vet you want to start the video call with. 
                        </li>
                        <li>
                        Start saving with <b>discounts of up to 70%.</b>
                        </li>
                    </ol>
                </>
            ); 
            break;

        case '/about-us':
            paragraphContent = (
                <>
                    <p>
                        <b>
                        Pet Lovers is linked to a virtual pet healthcare service called Golexi. 
                        services called Golexi.
                        </b>
                    </p> 
                    <br />
                    <p>       
                    To access your membership log in or go to <strong>portal.golexi.com</strong>
                    &nbsp;and enter your Member ID. Make your pet's healthcare
                    experience smarter and more enjoyable. 
                    </p>
                    <br/>
                    <p>
                        <b>Download our app, available for Apple or Android.</b>&nbsp;Use our
                        mobile application to connect with a veterinarian and get quick
                        and easy answers to all your pet health care questions and
                        advice      
                    </p>
                   
                </>
            );
            break;

        default:
            paragraphContent = null;

    }
    return (
        <section className="ProductDescription__main-container">
            <div className="ProductDescription__explanation-container">
                <div className="ProductDescription__title-container">
                    <h1>{props.title}</h1>
                </div>
                <div className="ProductDescription__explanation-container">
                   {paragraphContent} 
                </div>
            </div>

            <div className="ProductDescription__img-container">
                <div className="ProductDescription__img-inner-container">
                    <img src={props.img} alt={props.title} className="ProductDescription__img" />
                </div>
            </div>
        </section>
    );
};

export default ProductDescription;