import React, { useEffect } from "react";


import WelcomeSection from '../../shared/components/WelcomeSection/WelcomeSection';
import SectionInfo from '../../shared/components/SectionInfo/SectionInfo';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';
import cuddleDog from '../../utils/assets/price/cuddle-a-dog.png'

import ourCarePlansBannerImg from '../../utils/assets/price/cuddle-a-cat.png';
import SubscriptionPlan from "../../Home/components/SubscriptionPlan/SubscriptionPlan";



const Price = props => {

    useEffect(()=>{
        window.scroll(0,0);
    },[])

     const bannerSectionInfo =
     {
        cardTitle: "Give them that unconditional love they deserve", 
        cardButtonText: "Try Now",
        lengendBackground: ourCarePlansBannerImg
     }
     const subscriptionInformation = [
        {       
            subscriptionType: "MONTH",
            subscriptionPrice: "36.72",
            subscriptionTrialInfo: "Trial for 30 days at ",
            subscriptionTrialPrice : "3.29",
            subscriptionDescription: "Monthly",
            subscriptionButtonText: "Buy"
        }, 
        {
            img: cuddleDog,
            title : "Are you ready to rest easy knowing your pet has a vet available 24/7?",
        }
    ]

    return ( 
        <React.Fragment>
             <WelcomeSection props={bannerSectionInfo}/>
             <NavegationInfo page="price"/>
             <SectionInfo title={"Get a 30-day trial version for ONLY $3.29"} />
             <SubscriptionPlan props={subscriptionInformation}/>
        </React.Fragment>
    );
};

export default Price;