import React, {useEffect} from "react";


import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";
import Refund from "../components/Refund/Refund";
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";

import refundPolicyImg from '../../utils/assets/refundPolicy/dog-and-cat-together-on-a-couch.png';





const RefundPolicy = props => {
    const WelcomeSectionInfo =
    {
        cardTitle: "Unhappy with our service?",
        cardButtonText: "",
        lengendBackground: refundPolicyImg
    }
    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])
    return (
        <React.Fragment>
         
            <WelcomeSection props={WelcomeSectionInfo}/>
            <NavegationInfo page="Refund policy"/>
            <Refund />
       
            
           
            
        </React.Fragment>
    );
};

export default RefundPolicy;