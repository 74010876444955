import React, { useEffect } from "react";

import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";
import MembershipTerms from '../components/MembershipTerms/MembershipTerms';

import termsOfServiceBannerImg from '../../utils/assets/termsOfService/terms-and-conditions.png';
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";

const TermsOfService = props => {

    useEffect(()=>{
        window.scroll(0,0);
    },[])

    const bannerSectionInfo =
     {
        cardTitle: "Terms and conditions for the use of this service",
        cardBodyText: `If you have any questions or would like more information, please contact us!`,
        cardButtonText: "Make a Call",
        lengendBackground: termsOfServiceBannerImg
     }

    return (
        <React.Fragment>
           <WelcomeSection props={bannerSectionInfo}/>
           <NavegationInfo page="Terms of membership"/>
            <MembershipTerms/>
        </React.Fragment>
    );
};

export default TermsOfService;