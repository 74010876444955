import React, { useEffect } from "react";

import contactUsBannerImg from '../../utils/assets/contact/contact-us.png';
import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";
import ContactUs from '../components/ContactUs/ContactUs';




const Contact = props => {


    useEffect(()=>{
        window.scroll(0,0);
    },[])

    const bannerSectionInfo =
    {
       cardTitle: "Whatever the situation, we are here to help",
       cardButtonText: "",
       lengendBackground: contactUsBannerImg
    }

    return (
        <React.Fragment>
            <WelcomeSection props={bannerSectionInfo}/>
            <NavegationInfo page="Contact"/>
            <ContactUs />

        </React.Fragment>
    );
};

export default Contact;