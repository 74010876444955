import React from "react";
import './SubscriptionPlan.css';

import ContactCard from '../../../shared/components/ContactCard/ContactCard';
import SubscriptionCard from '../../../shared/components/SubscriptionCard/SubscriptionCard';
import SubscriptionDescription from '../../../shared/components/SubscriptionDescription/SubscriptionDescription';








const SubscriptionPlan = ({props}) => {

    const [subscriptionCardInformation, subscriptionInformation] = props;

    return (
        <>
        <section className="SubscriptionPlan__main-section-container" id="price">
            <div className="SubscriptionPlan__subscription-plans-container" id="pricing">
                <div className="SubscriptionPlan__subscription-description-component-container">
                    <SubscriptionDescription props={subscriptionInformation} />
                </div>
                <div className="SubscriptionPlan__subscription-card-component-container" >
                    <SubscriptionCard  props={subscriptionCardInformation} />
                </div>
            </div>
        </section>
        <section className="SubscriptionPlan__card-contact-component-container">
            <div className="SubscriptionPlan__contact-card">
                <ContactCard/>
            </div>
        </section>
        </>
    );
};

export default SubscriptionPlan;