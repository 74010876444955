import React, { useEffect } from "react";

import WelcomeSection from '../../shared/components/WelcomeSection/WelcomeSection';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';
import ProductDescription from '../../shared/components/ProductDescription/ProductDescription';
import SectionInfo from '../../shared/components/SectionInfo/SectionInfo';
import Benefits from "../components/Benefits/Benefits";
import Faq from '../components/Faq/Faq';

import aboutUsBannerImg from '../../utils/assets/aboutUs/know-about-us.png';
import vitualCallImg from '../../utils/assets/aboutUs/virtual-call.png';


const AboutUs = props => {

   useEffect(()=>{
      window.scroll(0,0);
  },[])

    const bannerSectionInfo =
     {
        cardTitle: "Make sure your best friend gets everything he needs",
        cardButtonText: "Join us",
        lengendBackground: aboutUsBannerImg
     }

     const productDescription = {
        title : "Find out more about who we are",
        img : vitualCallImg

     }
          
    return(
        <React.Fragment>
           <WelcomeSection props={bannerSectionInfo}/>
           <NavegationInfo page="About us"/>
           <ProductDescription props={productDescription} />
           <SectionInfo title = "Benefits" backgroundColor="#F2D479" textColor= "#B20918"/>
           <Benefits/>
           <Faq/>
        </React.Fragment>
    );
};

export default AboutUs;