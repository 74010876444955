import React from "react";
import { useHistory, useLocation } from 'react-router-dom';
import './WelcomeSection.css';

const WelcomeSection = ( {props} ) => {

    return(
        <section className="WelcomeSection__main-section-container" style={{backgroundImage: `url(${props.lengendBackground})`}}> 
            
            <CardInfo props={props}></CardInfo>
           
        </section> 
    );
};


const CardInfo = ( { props }) =>{
    const history = useHistory();
    const location = useLocation();
    const cellphone = "18883701674";

    let cardBodyText;
    switch (window.location.pathname) {
        case '/':
            if (props.cardBannerPosition === 1) {
                cardBodyText = (
                    <p>
                        Get unlimited access to a network of board-
                        certified veterinarians at your service 24/7. With a 
                        simple video call, <span className="CardInfo__important-text"> you can clear up any questions
                        you have right away.           
                        </span>
                    </p>
                )
            }
            else if (props.cardBannerPosition === 2) {
                cardBodyText = (
                    <p>
                        Get a second opinion, <span className="CardInfo__important-text">clarify doubts about your 
                        pet's health,</span> help with emergency recognition,
                        behavior issues, pet health advice and training tips. 
                    </p>
                )
            }
            else {
                cardBodyText = (
                    <p>
                        Save time and money with our exclusive telehealth
                        plans. Get care whenever and wherever you need it, &nbsp;
                        <span className="CardInfo__important-text">just a phone call away!</span>
                    </p>
                )
            }
            break;

        case '/price':

            cardBodyText = (
                <p>
                    Have a veterinarian available 24 hours a day, 7 days
                    a week, anytime and anywhere with <span className="CardInfo__important-text">savings of up 
                    to 75% on pet medications.</span> 
                </p> 
            )

            break;

        case '/contact-us':
            cardBodyText = (
                <p>
                    Take advantage of our advice on how you can train,
                    educate, and care for your pet to
                    <span className="CardInfo__important-text">&nbsp;make a difference
                    in their life.
                    </span>
                </p>
            )
            break;
 
        case '/cancel-service':
            cardBodyText = (
                <p>
                    <span className="CardInfo__important-text">It is quick and easy to cancel your service.</span>&nbsp;We hope
                    that by choosing the reason for your cancellation,
                    you will help us to improve our service. 
                </p>
            )

            break;

        case '/terms-of-service':
            cardBodyText = (
                <p>
                    If you have any questions or would like more 
                    information, <span className="CardInfo__important-text"> please contact us!</span>

                </p>
            )
            break;
         
        case '/privacy-policy':
            cardBodyText = (
                < p>
                    <span className="CardInfo__important-text">At Pet Lovers, your privacy is important to us.  </span> If you 
                    have any questions or concerns, please contact us 
                    for assistance.  
                </p>
            )
            break; 
        case '/refund-policy':
            cardBodyText = (
                <p>
                    <span className="CardInfo__important-text">We appreciate any feedback on what went wrong
                    or how we can improve.</span> Please follow these steps 
                    to submit your refund request 
                </p>
            )
            break;
        case '/about-us':        
            cardBodyText = (
                <p>
                    Learn more about the benefits and join our 
                    community of members who use our services to&nbsp;
                    <span className="CardInfo__important-text">improve the quality of life for their pets.</span> 
                </p>
            ) 
            break;
        case '/checkout':
            cardBodyText = (
                <p> 
                    Clear up your doubts, get incredible advice,
                    exclusive discounts, and <span className="CardInfo__important-text">have 24/7 access to a
                    board-certified vet who is just a video call away. </span>
                </p>
            ) 
            break;
        default:
            cardBodyText = (
                <p> 
                    It is possible that what you are looking for may have
                    been moved or may no longer be part of the site. &nbsp;
                    <span className="CardInfo__important-text">Below is a list of useful links.</span>
                </p>
            ) 
            break;
    }

    const goToHandle = () => {
      
        if (location.pathname === '/' || location.pathname === '/about-us' || location.pathname === '/price' ||
            location.pathname === '/privacy-policy' ||
            location.pathname === '/reimbursement-guidelines' 
        ) {
            history.push('/contact-us')
        }
        else if ( location.pathname === '/terms-of-service'){
            window.location.href = `tel:${cellphone}`;
        }



    }
    return(
       
        <div className="WelcomeSection__main-card-info-container">

            <div className="WelcomeSection__main-title-container">
                <h2 className="text-left">{props.cardTitle}</h2>
            </div>
            <div className="WelcomeSection__main-body-container">
                <div>
                {cardBodyText}
                </div>
            </div>
            {
                props.cardButtonText.trim() !== "" ? (
                    <div className="WelcomeSection__main-button-container">
                        <div className="WelcomeSection__inner-button-container">
                        <a href="" onClick={(e) => { e.preventDefault(); goToHandle('contact-us')}}>
                                {props.cardButtonText}
                            </a>
                        </div>
                    </div>
                ) : (
                    <div className="WelcomeSection__button-container" style={{ minWidth : '10em'}}>
                    </div>
                )
            }
        </div>
     
    )
}


export default WelcomeSection;