import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
import ReCAPTCHA from 'react-google-recaptcha';


import './CheckoutForm.css'
import localStorageService from '../../../../../utils/helpers/services/localStorageService';
import { IDENTITY_SERVICES_PRODUCTS } from '../../../../../utils/helpers/constants/localStorageKeys';
import { DATA_COMES_FROM } from '../../../../../utils/helpers/constants/formValue';
import error from '../../../../../utils/helpers/errors/formErrors';
import validate from '../../../../../utils/helpers/validators/dataValidation';
import { STATES } from '../../../../../utils/helpers/constants/states';
import SubscriptionCard from '../../../../../shared/components/SubscriptionCard/SubscriptionCard';
import {submitPayment} from '../../../../../utils/helpers/services/checkoutService';
import Loader from '../../../../../shared/components/Loader/Loader'; 





const CheckoutForm = () => {

    let styleForUnconfirmRecaptcha = {
        backgroundColor : '#f2ebd5',
        border : '1px solid #bd2f38',
        color : '#000000'
    }
    let styleForConfirmRecaptcha = {
        backgroundColor : '#b20918',
        border: 'none',
        color : '#ffffff'
    }
    
    const [loading, setLoading] = useState(false);
    const [recaptcha, setRecaptcha] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const product = localStorageService.getProduct(IDENTITY_SERVICES_PRODUCTS.CARD_PRODUCT_SUBSCRIPTON);
    const subscriptionCardInformation = {
        subscriptionType: product.subscriptionType,
        subscriptionPrice: product.subscriptionPrice,
        subscriptionTrialInfo: product.subscriptionTrialInfo,
        subscriptionTrialPrice: product.subscriptionTrialPrice,
        subscriptionDescription: product.subscriptionDescription,
        subscriptionButtonText: ""
    }


    useEffect(() => {
        if (localStorageService.getProduct(IDENTITY_SERVICES_PRODUCTS.TERMS_AND_CONDITIONS)) setIsChecked(true);
    }, [])

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
        localStorageService.saveProduct(IDENTITY_SERVICES_PRODUCTS.TERMS_AND_CONDITIONS, e.target.checked);
    };


    const [formData, setFormData] = useState({
        cardNumber: '',
        fcvv: '',
        expDate: '',
        firstName: '',
        city: '',
        zipCode: '',
        email: '',
        lastName: '',
        state: '',
        address: '',
        addressApt: '',
        phoneNumber: ''
    });

    const [errorState, setErrorState] = useState({
        cardNumber: false,
        fcvv: false,
        expDate: false,
        firstName: false,
        city: false,
        zipCode: false,
        email: false,
        lastName: false,
        state: false,
        address: false,
        addressApt: false,
        phoneNumber: false
    });

    const [errorMessages, setErrorMessages] = useState({
        cardNumber: '',
        fcvv: '',
        expDate: '',
        firstName: '',
        city: '',
        zipCode: '',
        email: '',
        lastName: '',
        state: '',
        address: '',
        addressApt: '',
        phoneNumber: ''
    });


    const resetFormValues = () => {
        setFormData({
            cardNumber: '',
            fcvv: '',
            expDate: '',
            firstName: '',
            city: '',
            zipCode: '',
            email: '',
            lastName: '',
            state: '',
            address: '',
            addressApt: '',
            phoneNumber: ''
        });
        setErrorState({
            cardNumber: false,
            fcvv: false,
            expDate: false,
            firstName: false,
            city: false,
            zipCode: false,
            email: false,
            lastName: false,
            state: false,
            address: false,
            addressApt: false,
            phoneNumber: false
        });
        setErrorMessages({
            cardNumber: '',
            fcvv: '',
            expDate: '',
            firstName: '',
            city: '',
            zipCode: '',
            email: '',
            lastName: '',
            state: '',
            address: '',
            addressApt: '',
            phoneNumber: '',
        });


    }


    const handleInputChange = (e) => {
        
        
        const { name, value } = e.target;
        if (name === 'flexCheckTerms') {
            handleCheckboxChange(e);
        }
        const {isValid, theError} = error.validateField(name, value);
        setErrorState((prevErrorState) => ({ ...prevErrorState, [name]: !isValid }));
        setErrorMessages((prevErrorMessages) => ({ ...prevErrorMessages, [name]: isValid ? '' : theError }));
        setFormData((prevData) => ({ ...prevData, [name]: value }));

    };



    const onSubmitHandler = (e) => {
        e.preventDefault();
        let isFormInvalid = {
            isValid : true,
            theError : ''
        }
        const productPrice = localStorageService.getProduct(IDENTITY_SERVICES_PRODUCTS.CARD_PRODUCT_SUBSCRIPTON).subscriptionTrialPrice;
        if (!isChecked) {
            Swal.fire({
                title: 'Error!',
                text: 'You must accept terms and conditions',
                icon: 'error',
                confirmButtonText: 'Sure!',
                confirmButtonColor: '#07448a'
            });
            return;
        }

        

        if (validate.requieredFieldsToSentData(DATA_COMES_FROM.CHECKOUT_FORM, formData) === false) {

            Swal.fire({
                title: 'Error!',
                text: 'You must fill out the fields to continue',
                icon: 'error',
                confirmButtonText: 'Sure!',
                confirmButtonColor: '#07448a'
            });
            return;

        } 

        validate.allFieldsBeforeTheCheckout(formData).forEach(( response )=>{
            if ( response.isValid === false ){
                isFormInvalid = {
                    isValid : response.isValid,
                    theError : response.typeError
                }
            }
        })

        if( validate.hasCardValidExpirationDate(formData.expDate) === false){
            Swal.fire({
                title: 'Error!',
                text: 'The expiration date of your card is not valid',
                icon: 'error',
                confirmButtonText: 'I am going to change it!',
                confirmButtonColor: '#07448a'
              });
              return;
        }
        
        
        
        if( isFormInvalid['isValid']){

            setLoading(true);
            formData.productPrice = productPrice;
            formData.cardType = validate.identifyCardType( formData.cardNumber );

            submitPayment(validate.cleanSpaces(formData))
            .then( result =>{
                setLoading(false);
                setRecaptcha(false);
                const response = JSON.parse(result);
                if( response.ResponseCode  === 200){
                    Swal.fire({
                        title: 'Successful transaction',
                        text: `${response.ResponseData}. Dear customer, you will receive an email with all the information about your order `,
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#b20918'
                    }).then((result) => {
                        if (result.isConfirmed || result.isDismissed) {
        
                            localStorageService.deleteAcceptanceOfTermsAndServices(IDENTITY_SERVICES_PRODUCTS.TERMS_AND_CONDITIONS);
                            localStorageService.deleteProduct(IDENTITY_SERVICES_PRODUCTS.CARD_PRODUCT_SUBSCRIPTON);
                            sessionStorage.clear();
                            window.location.href = '/';
                        }
                    })
        
                    return;
                }
                else if( response.ResponseCode === 400){
                    Swal.fire({
                        title: 'Error!',
                        text: `${response.ResponseData}`,
                        icon: 'error',
                        confirmButtonText: "Ok",
                        confirmButtonColor: '#b20918'
                      });
                      return;
                }
                else{
                    Swal.fire({
                        title: 'Error!',
                        text: `${response.ResponseData}`,
                        icon: 'error',
                        confirmButtonText: "Ok",
                        confirmButtonColor: '#b20918'
                      });
                      return;
                }
            })
            .catch(error =>{
                setLoading(false);
                setRecaptcha(false);
                    console.log(error);
                    Swal.fire({
                        title: "Error",
                        text: "Error sending the checkout information. Please try again",
                        icon: "error",
                        confirmButtonColor : '#b20918'
                      })
                      return;
            })
       
        }
        
        else {
            Swal.fire({
                title: 'Error!',
                text: `${isFormInvalid['theError']}`,
                icon: 'error',
                confirmButtonText: "I am going to change it",
                confirmButtonColor: '#b20918'
              });
              return;
        }



    }

    return (
        loading ? <Loader isLoading={loading}></Loader> : (
            <div className='CheckoutForm__main-container'>
            <div className='CheckoutForm__payment-detail-container'>
                <div className='CheckoutForm__payment-detail'>

                    <div className='CheckoutForm__form-container'>
                        <form >
                            <div className='CheckoutForm__description'>
                                <h5>Payment Details</h5>
                                <p>
                                    We accept Visa, Mastercard, American Express
                                    and UnionPay.
                                </p>
                            </div>
                            <div className="mb-2 mt-2">
                                <label htmlFor="cardNumber" className="form-label">Card Number</label>
                                <input type="text" 
                                       className="form-control m-0" 
                                       id="cardNumber" 
                                       name='cardNumber' 
                                       placeholder='xxxx - xxxx - xxxx - xxxx' 
                                       onChange={handleInputChange} 
                                       value={formData.cardNumber} 
                                />
                                {errorState.cardNumber && <span className="text-danger" style={{fontWeight : 'bold'}}>{errorMessages.cardNumber}</span>}
                            </div>
                            <div className='CheckoutForm__payment-division-container'>
                                <div className="mb-1 mt-2">
                                    <label htmlFor="fcvv" className="form-label" >CVV</label>
                                    <input type="text" 
                                           className="form-control m-0" 
                                           id='fcvv' 
                                           name='fcvv' 
                                           placeholder='123' 
                                           onChange={handleInputChange} 
                                           value={formData.fcvv} />
                                    {errorState.fcvv && <span className="text-danger" style={{fontWeight : 'bold'}}>{errorMessages.fcvv}</span>}
                                </div>
                                <div className="mb-1 mt-2">
                                    <label htmlFor="expDate" className="form-label">EXP Day</label>
                                    <input type="text" 
                                           className="form-control m-0" 
                                           id='expDate' 
                                           name='expDate' 
                                           placeholder='MM - YY' 
                                           onChange={handleInputChange} 
                                           value={`${formData.expDate}`} 
                                    />
                                    {errorState.expDate && <span className="text-danger" style={{fontWeight : 'bold'}}>{errorMessages.expDate}</span>}
                                </div>
                            </div>
                        </form>
                    </div>


                    
                </div>
                <div className='CheckoutForm__product-detail'>
                    <div className='CheckoutForm__subscription-card-container'>
                        <SubscriptionCard props={subscriptionCardInformation} />
                        <div className="form-check CheckoutForm__terms-container">
                            <input className="form-check-input" 
                                   type="checkbox" 
                                   id="flexCheckTerms" 
                                   name='flexCheckTerms' 
                                   onChange={handleInputChange} 
                                   checked={isChecked} 
                            />
                            <label className="form-check-label CheckoutForm__lbl-terms">
                                I have read and I accept the <Link to='/terms-of-service'>Terms of service</Link>.
                            </label>
                        </div>
                        <div className='CheckoutForm__orders-container'>
                            <div className='CheckoutForm__order'>
                                <span>Order summary</span>
                                <div className='CheckoutForm__total-container' >
                                    <span className='CheckoutForm__crossed-out-price'>${`${product.subscriptionPrice}`}</span>
                                    <span className='CheckoutForm__price' style={{ fontWeight: '700' }}>${`${product.subscriptionTrialPrice}`}</span>
                                </div>

                            </div>
                            <div className='CheckoutForm__total'>
                                <span>Total</span>
                                <div className='CheckoutForm__total-container'>
                                    <span className='CheckoutForm__crossed-out-price'>${`${product.subscriptionPrice}`}</span>
                                    <span className='CheckoutForm__price'>${`${product.subscriptionTrialPrice}`}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='CheckoutForm__customer-data-form-container'>
                <form onSubmit={onSubmitHandler} >
                    <div className="CheckoutForm__title-container">
                        <h5 className='CheckoutForm__title'>Billing Address</h5>
                    </div>
                    <div className='CheckoutForm__division-container'>
                        <div className='CheckoutForm__left-container'>
                            <div className="mb-1">
                                <label htmlFor="firstName" className="form-label">First Name</label>
                                <input type="text" 
                                       className="form-control m-0" 
                                       id="firstName" 
                                       name='firstName' 
                                       placeholder='Your name' 
                                       onChange={handleInputChange} 
                                       value={formData.firstName} 
                                       required autoComplete='off' 
                                       />
                                {errorState.firstName && <span className="text-danger" style={{fontWeight : 'bold'}}>{errorMessages.firstName}</span>}
                            </div>
                            <div className='mb-1'>
                            <label className="form-label">State</label>
                            <select className="form-select mb-1" 
                                    aria-label="Default select example" 
                                    id='state' 
                                    name='state' 
                                    onChange={handleInputChange} 
                                    value={formData.state} 
                                    equired autoComplete='off'>
                                <option value='' disabled>
                                    Choose your state
                                </option>
                                {
                                    STATES.map((state, index) => (
                                        <option key={index} value={state.label}>
                                            {state.label}
                                        </option>
                                    ))
                                }
                            </select>
                            {errorState.state && <span className="text-danger" style={{fontWeight : 'bold'}}>{errorMessages.state}</span>}
                            </div>
                            <div className="mb-1">
                                <label htmlFor="city" className="form-label">City</label>
                                <input type="text" 
                                       className="form-control m-0" 
                                       id="city" 
                                       name='city' 
                                       placeholder='Your city' 
                                       onChange={handleInputChange} 
                                       value={formData.city} 
                                       required autoComplete='off' 
                                       />
                                {errorState.city && <span className="text-danger" style={{fontWeight : 'bold'}}>{errorMessages.city}</span>}
                            </div>
            
                            <div className="mb-1">
                                <label htmlFor="zipCode" className="form-label">Zip Code </label>
                                <input type="text" 
                                        className="form-control m-0" 
                                        id="zipCode" 
                                        name='zipCode' 
                                        placeholder='xxxx' 
                                        onChange={handleInputChange} 
                                        value={formData.zipCode} 
                                        required 
                                        autoComplete='off' 
                                />
                                {errorState.zipCode && <span className="text-danger" style={{fontWeight : 'bold'}}>{errorMessages.zipCode}</span>}
                            </div>
                            <div className="mb-1">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input type="email" 
                                        className="form-control m-0" 
                                        id="email" 
                                        name='email' 
                                        placeholder='example@example.com' 
                                        onChange={handleInputChange} 
                                        value={formData.email} 
                                        required 
                                        autoComplete='off' />
                                {errorState.email && <span className="text-danger" style={{fontWeight : 'bold'}}>{errorMessages.email}</span>}
                            </div>
                        </div>
                        <div className='CheckoutForm__right-container'>
                            <div className="mb-1">
                                <label htmlFor="lastName" className="form-label">Last Name</label>
                                <input type="text" 
                                       className="form-control m-0" 
                                       id="lastName" 
                                       name='lastName' 
                                       placeholder='Your last name' 
                                       onChange={handleInputChange} 
                                       value={formData.lastName} 
                                       required 
                                       autoComplete='off' />
                                {errorState.lastName && <span className="text-danger" style={{fontWeight : 'bold'}}>{errorMessages.lastName}</span>}
                            </div>
                            
                            <div className="mb-1">
                                <label htmlFor="address" className="form-label">Address 1</label>
                                <input type="text" 
                                       className="form-control m-0" 
                                       id="address" 
                                       name='address' 
                                       placeholder='Your address' 
                                       onChange={handleInputChange} 
                                       value={formData.address} 
                                       required autoComplete='off' />
                                {errorState.address && <span className="text-danger" style={{fontWeight : 'bold'}}>{errorMessages.address}</span>}
                            </div>
                            <div className="mb-1">
                                <label htmlFor="addressApt" className="form-label">addressApt 2</label>
                                <input type="text" 
                                       className="form-control m-0" 
                                       id="addressApt" 
                                       name='addressApt' 
                                       placeholder='Your address' 
                                       onChange={handleInputChange} 
                                       value={formData.addressApt} 
                                       required autoComplete='off' />
                                {errorState.addressApt && <span className="text-danger" style={{fontWeight : 'bold'}}>{errorMessages.addressApt}</span>}
                            </div>
                            <div className="mb-1">
                                <label htmlFor="phoneNumber" 
                                      className="form-label">Phone Number</label>
                                <input type="text" 
                                       className="form-control m-0" 
                                       id="phoneNumber" 
                                       name='phoneNumber' 
                                       placeholder='(+01) xxx - xxxx - xxxxx' 
                                       onChange={handleInputChange} 
                                       value={formData.phoneNumber} 
                                       required 
                                       autoComplete='off' />
                                {errorState.phoneNumber && <span className="text-danger" style={{fontWeight : 'bold'}}>{errorMessages.phoneNumber}</span>}
                            </div>
                        </div>
                    </div>
                    <div className='CheckoutForm__btn-container'>
                    <div className="CheckoutForm__recaptcha-container">
                        <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA}
                    onChange={(val)=> setRecaptcha(val)}
                    />
                    </div>
                        <div className='CheckoutForm__btn'>
                            <button type="button" className="ContactForm__btn mt-2 mb-2" onClick={resetFormValues}>Reset</button>
                            <button type="submit" className="ContactForm__btn ContactForm__send-btn mt-2 mb-2" disabled={!recaptcha} style={recaptcha ? styleForConfirmRecaptcha: styleForUnconfirmRecaptcha }>Purchase</button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
        )
    );
};

export default CheckoutForm;